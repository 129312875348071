import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Grid, Stack, Typography } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import {
  useCreateMechanicalWarrantyWorksheet,
  useEditMechanicalWarrantyWorksheet,
} from '@src/data/api/worksheet-api/mechanical-warranty-worksheet-api'
import { reportErrorToConsole } from '@src/services/error-logger'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { buildMechanicalWarrantyWorksheetSchema, MechanicalWarrantyWorksheet } from '@src/types/WorksheetSchema'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MechanicalWarrantyExtended from './components/MechanicalWarrantyExtended'
import MechanicalWarrantyWorksheetDetails from './components/MechanicalWarrantyWorksheetDetails'

interface Props {
  merchant: Merchant
  creditApplication: CreditApplication
}

const MechanicalWarrantyWorksheetSchema = buildMechanicalWarrantyWorksheetSchema()

const MechanicalWarrantyWorksheetPage = ({ merchant: _, creditApplication }: Props) => {
  const [defaultValues] = useState<MechanicalWarrantyWorksheet>({
    ...(MechanicalWarrantyWorksheetSchema.getDefault() as unknown as MechanicalWarrantyWorksheet),
    ...creditApplication.worksheet,
  } as unknown as MechanicalWarrantyWorksheet)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [createWorksheet, isCreating, hasCreateError, createError] = useCreateMechanicalWarrantyWorksheet()
  const [editWorksheet, isEditting, hasEditError, editError] = useEditMechanicalWarrantyWorksheet()

  const methods = useForm<MechanicalWarrantyWorksheet>({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(MechanicalWarrantyWorksheetSchema),
  })

  const onSubmit = methods.handleSubmit((data) => {
    if (!isCreating && !isEditting) {
      if (creditApplication.worksheet) {
        editWorksheet({ worksheet: data })
          .then(() => {
            navigate('..')
          })
          .catch(reportErrorToConsole)
      } else {
        createWorksheet({ worksheet: data })
          .then(() => {
            navigate('..')
          })
          .catch(reportErrorToConsole)
      }
    }
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} noValidate>
        {(createError || editError) && (
          <Alert severity="error" sx={{ mb: 2 }}>
            <Typography>{createError?.response?.data?.message || editError?.response?.data?.message}</Typography>
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Typography flexGrow={1} variant="h4">
                {t('mechanicalWarrantyWorksheet.title')}
              </Typography>
              <Stack direction="row" spacing={1}>
                <AsyncActionButton fullWidth={false} variant="contained" href="..">
                  {t('common.previous')}
                </AsyncActionButton>

                <AsyncActionButton
                  fullWidth={false}
                  variant="contained"
                  type="submit"
                  isPending={isCreating || isEditting}
                  isError={hasCreateError || hasEditError}
                >
                  {t('worksheet.createContract')}
                </AsyncActionButton>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <MechanicalWarrantyExtended />
          </Grid>
          <Grid item xs={8}>
            <MechanicalWarrantyWorksheetDetails creditApplication={creditApplication} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default MechanicalWarrantyWorksheetPage
