import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface AlertMessageProps {
  applicantAlerts?: Record<string, boolean>
  coapplicantAlerts?: Record<string, boolean>
  warningId: string
  warningMessage: string
}

const CreditAlertMessage = ({ applicantAlerts, coapplicantAlerts, warningId, warningMessage }: AlertMessageProps) => {
  const { t } = useTranslation()

  const hasApplicantAlert = applicantAlerts?.[warningId]
  const hasCoApplicantAlert = coapplicantAlerts?.[warningId]

  if (!hasApplicantAlert && !hasCoApplicantAlert) {
    return null
  }

  return (
    <Typography variant="body1">
      {hasApplicantAlert && t('viewCreditApplication.applicant')}
      {hasApplicantAlert && hasCoApplicantAlert && ', '}
      {hasCoApplicantAlert && t('viewCreditApplication.coApplicant')}
      {': '}
      {warningMessage}
    </Typography>
  )
}

export default CreditAlertMessage
