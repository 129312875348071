import { useCreditApplicationById } from '@src/data/api/credit-api/credit-api'
import { EFinancingProgram } from '@src/types/Constants'
import { Navigate } from 'react-router-dom'
import { useUser } from '@src/contexts/AppContext'
import { useMerchantById, useMerchantByParentId } from '@src/data/api/merchants-api/merchants-api'
import { useGetHolidaysForYear } from '@src/data/api/worksheet-api/worksheet-api'
import PageSpinner from '@src/components/PageSpinner'
import useFeatureFlags from '@src/hooks/config-hooks'
import CreateWorksheetPage from '.'

interface Props {
  id: string
  financingProgramId: EFinancingProgram
}
const WorksheetWrapper = ({ id, financingProgramId }: Props) => {
  const [creditApplication, isLoading] = useCreditApplicationById(
    { creditApplicationId: id, financingProgramId },
    false,
  ) // if true, poll every 5 secs
  const user = useUser()
  const [merchant, isLoadingMerchant] = useMerchantById(creditApplication?.merchantId ?? '')
  const [merchantsListFromParentId, isFetchingMerchantsList] = useMerchantByParentId(user?.merchantId ?? '')
  const [listHolidays, isLoadingHolidays] = useGetHolidaysForYear(new Date().getFullYear())
  const { flags, isFetchingFeatureSwitch } = useFeatureFlags()
  const isLoadingInfo =
    isLoading || isLoadingMerchant || isFetchingMerchantsList || isLoadingHolidays || isFetchingFeatureSwitch
  if (!isLoadingInfo) {
    if (!user || !merchant || !merchantsListFromParentId || !listHolidays || !creditApplication) {
      return <Navigate to="/" />
    }

    return (
      <CreateWorksheetPage
        creditApplication={creditApplication}
        merchant={merchant}
        listHolidays={listHolidays}
        user={user}
        merchantsListFromParentId={merchantsListFromParentId}
        allowWeekendOrHolidayActivationDate={flags.UseAllowWeekendOrHolidayActivationDate}
        useIncludeInsuranceAllProvinces={flags.UseIncludeInsuranceAllProvinces}
      />
    )
  }
  return <PageSpinner withBackdrop isLoading={isLoadingInfo} />
}
export default WorksheetWrapper
