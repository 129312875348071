import { IconButton, ListItem, ListItemText, Tooltip, Typography } from '@mui/material'
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import { ApplicantJob } from '@src/types/CreditApplicationSchema'
import { FormatCurrencyNoDecimals, formatAddress } from '@src/services/Formatter'
import { useTranslation } from 'react-i18next'

interface Props {
  onDelete?: () => void
  onEdit?: () => void
  data: ApplicantJob
  disableEdit: boolean
  toolTip: string
}

const DisplayJobInfosListItem = ({ onDelete, onEdit, data, disableEdit, toolTip }: Props) => {
  const { address } = data
  const { t } = useTranslation()

  return (
    <ListItem
      sx={{ pr: 10 }}
      disableGutters
      secondaryAction={
        <>
          {onEdit && (
            <IconButton id="editJob" aria-label="edit" onClick={onEdit} disabled={disableEdit}>
              <EditIcon />
            </IconButton>
          )}
          {onDelete && (
            <IconButton id="deleteJob" aria-label="delete" onClick={onDelete} disabled={disableEdit} color="error">
              <DeleteIcon />
            </IconButton>
          )}
        </>
      }
    >
      <Tooltip title={toolTip}>
        <ListItemText
          primary={
            <Typography variant="subtitle1">{`${data.employerName} (${t(`jobType.${data.jobType}`)}) : ${
              data.jobTitle
            } ${FormatCurrencyNoDecimals(data.annualSalary)}`}</Typography>
          }
          secondary={formatAddress(address)}
        />
      </Tooltip>
    </ListItem>
  )
}

export default DisplayJobInfosListItem
