import { Card, CardContent, Divider, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  canSkipFlinks: boolean
}

const FlinksOptionBox = ({ canSkipFlinks }: Props) => {
  const { t } = useTranslation()

  return (
    <Card variant="outlined">
      <CardContent>
        {canSkipFlinks && (
          <>
            <Typography variant="h5">Option 1</Typography>
            <Divider sx={{ marginBottom: '1rem' }} />
          </>
        )}
        <Typography variant="h6" marginBottom="1rem">
          {t('incomeVerification.bankAccountProcedure.optionOne')}
        </Typography>
        <Link href="https://flinks.com/about/" target="_blank">
          {t('incomeVerification.bankAccountProcedure.whatsFlinks')}
        </Link>
        <Typography color="primary" marginTop="1rem">
          {t('incomeVerification.bankAccountProcedure.step')} 1
        </Typography>
        <Typography>{t('incomeVerification.bankAccountProcedure.authenticate')}</Typography>
        <Typography color="primary" marginTop="1rem">
          {t('incomeVerification.bankAccountProcedure.step')} 2
        </Typography>
        <Typography>{t('incomeVerification.bankAccountProcedure.authorize')}</Typography>
        <Typography color="primary" marginTop="1rem">
          {t('incomeVerification.bankAccountProcedure.step')} 3
        </Typography>
        <Typography>{t('incomeVerification.bankAccountProcedure.automated')}</Typography>
      </CardContent>
    </Card>
  )
}

export default FlinksOptionBox
