import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import { EFinancingProgram } from '@src/types/Constants'
import DocumentTableRow from './DocumentTableRow'

interface Props {
  requiredDocuments: RequiredDocument[]
  creditApplicationId: string
  financingProgramId: EFinancingProgram
  setError: Dispatch<Error>
  canEdit: boolean
}

const DocumentTable = ({ requiredDocuments, creditApplicationId, financingProgramId, setError, canEdit }: Props) => {
  const { t } = useTranslation()
  const docs = requiredDocuments
  return (
    <>
      <Paper sx={{ mt: 2 }}>
        <Toolbar>
          <Typography variant="h6" gutterBottom component="div">
            Documents
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('documentSectionHeaders.document')}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{t('documentSectionHeaders.required')}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{t('documentSectionHeaders.requestedOn')}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{t('documentSectionHeaders.receivedOn')}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{t('documentSectionHeaders.status')}</TableCell>
                <TableCell>{t('documentSectionHeaders.notes')}</TableCell>
                {canEdit && <TableCell sx={{ textAlign: 'center' }}>{t('documentSectionHeaders.action')}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {docs?.map((item: RequiredDocument) => (
                <DocumentTableRow
                  financingProgramId={financingProgramId}
                  documentRequired={item}
                  key={item.typeId}
                  creditApplicationId={creditApplicationId}
                  setError={setError}
                  canEdit={canEdit}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {!docs.length && (
        <div style={{ padding: '1rem 0 0 1rem', textAlign: 'center', width: '100%' }}>
          <Chip label={t('common.notRequiredDocuments')} size="medium" />
        </div>
      )}
    </>
  )
}

export default DocumentTable
