import { Grid } from '@mui/material'
import { DatePicker } from '@src/components/DatePicker'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { EPaymentPlan } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import { getFirstPaymentOnMinMax, ProductsWorksheet } from '@src/types/WorksheetSchema'
import { Control, FieldErrors, UseFormRegister, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { programConfigByFinancingProgramId } from '@src/data/creditapp-selectors'

interface Props {
  creditApplication: CreditApplication

  control: Control<ProductsWorksheet>

  register: UseFormRegister<ProductsWorksheet>

  errors: FieldErrors<ProductsWorksheet>
  disabledAmountInput?: boolean
  maxTermDuration?: number
  loanTermChoicesList: SelectValueListItem[]
}

const ProductsWorksheetDetails = ({
  creditApplication,
  register,
  errors,
  control,
  disabledAmountInput,
  loanTermChoicesList,
}: Props) => {
  const { t } = useTranslation()
  const [paymentFrequency, deliveryOn] = useWatch({ control, name: ['paymentFrequency', 'deliveryOn'] })
  const maxActivationDate = useMemo(() => new Date(creditApplication.expiresOn), [creditApplication.expiresOn])

  const [minFirstPaymentDate, maxFirstPaymentDate] = useMemo(() => {
    return getFirstPaymentOnMinMax(deliveryOn, paymentFrequency)
  }, [deliveryOn, paymentFrequency])

  const programConfig = programConfigByFinancingProgramId(creditApplication.financingProgramId)
  const availableFrequencyPayments = programConfig.frequencyPayments

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={4}>
        <InputTextField
          label={t('worksheet.requestedAmount')}
          fullWidth
          disabled={disabledAmountInput}
          {...register('amountRequested')}
          error={errors.amountRequested}
          defaultValue={creditApplication?.worksheet?.amountRequested}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DatePicker
          name="deliveryOn"
          control={control}
          error={errors?.deliveryOn}
          label={t('worksheet.activationDate')}
          disablePast
          minDate={new Date()}
          maxDate={maxActivationDate}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          valueList={loanTermChoicesList}
          label={t('worksheet.loanTerm') as string}
          {...register('term')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          valueList={availableFrequencyPayments.map((item) => {
            return { label: `common.${item}`, value: item }
          })}
          label={t('worksheet.paymentFrequency') as string}
          {...register('paymentFrequency')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DatePicker
          name="firstPaymentOn"
          control={control}
          error={errors?.firstPaymentOn}
          label={t('worksheet.firstPaymentOn')}
          disablePast
          minDate={minFirstPaymentDate}
          maxDate={maxFirstPaymentDate}
          disabled={!paymentFrequency}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          valueList={[
            {
              label: t(`ePaymentPlan.${EPaymentPlan.regularDailyInterests}`),
              value: EPaymentPlan.regularDailyInterests,
            },
          ]}
          label={t('worksheet.paymentPlan') as string}
          {...register('paymentPlanId')}
          error={errors?.paymentPlanId}
          disabled
        />
      </Grid>
    </Grid>
  )
}

export default ProductsWorksheetDetails
