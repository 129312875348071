import { Alert, AlertTitle, List, ListItemText } from '@mui/material'
import translate from '@src/services/translate'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

interface Props {
  serverErrors: Error
}

interface ServerErrors {
  message: string
  type: string
  fieldErrors: object
}

const ServerErrorsAlert = ({ serverErrors }: Props): JSX.Element => {
  const { t } = useTranslation()
  const response = (serverErrors as AxiosError)?.response
  const errors = response?.data as ServerErrors

  return (
    <Alert sx={{ mb: 2 }} severity="error">
      <AlertTitle>{t('common.error')}</AlertTitle>
      {errors?.type === 'FieldValidations' && (
        <List>
          {Object.values(errors?.fieldErrors).map((error) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            return <ListItemText key={Object.keys(error)[0]}>{error[0]}</ListItemText>
          })}
        </List>
      )}
      {(response?.status === 400 || response?.status === 404) && !errors?.type && (
        <div> {translate(`common.errors.${response.data as string}`)}</div>
      )}
      {response?.status === 409 && !errors?.type && <div> {t('conflict.modificationConflict')}</div>}
      {!errors && <div> {t(`common.errors.serverError`)}</div>}
    </Alert>
  )
}

export default ServerErrorsAlert
