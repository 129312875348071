import { InteractionStatus, InteractionType } from '@azure/msal-browser'
import { useMsal, useMsalAuthentication } from '@azure/msal-react'
import { loginRequest, protectedResources } from '@src/authConfig'
import { AppContext, useAppStore } from '@src/contexts/AppContext'
import { isExpiredToken, parseJwt } from '@src/data/api/api-client'
import { saveUserToken, signIn } from '@src/services/Authentification'
import { reportErrorToConsole } from '@src/services/error-logger'
import { UserDtoFromJwt } from '@src/types/User'
import { useEffect } from 'react'

interface Props {
  children: JSX.Element
}

const UserProvider = ({ children }: Props) => {
  const { instance, accounts, inProgress } = useMsal()
  useMsalAuthentication(InteractionType.Redirect, loginRequest)
  const appStore = useAppStore()

  useEffect(() => {
    if (accounts[0] && appStore.user === null && inProgress === InteractionStatus.None) {
      instance.setActiveAccount(accounts[0])
      instance
        .acquireTokenSilent({ account: accounts[0], scopes: protectedResources.getTokenAzure.scopes.read })
        .then((response) => {
          return signIn({ jwt: response.idToken }).then(async (r) => {
            const icebergToken = parseJwt(r.jwt) as UserDtoFromJwt
            if (isExpiredToken(icebergToken.exp)) {
              await instance.loginRedirect()
            }
            saveUserToken(r.jwt)
            appStore.reloadUser()
          })
        })
        .catch(reportErrorToConsole)
    }
  })

  if (inProgress !== 'none' || !accounts.length || appStore.user === null) return null
  return <AppContext.Provider value={appStore}>{children}</AppContext.Provider>
}

export default UserProvider
