import UploadFileIcon from '@mui/icons-material/UploadFile'
import { CircularProgress, IconButton, Stack } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { useUploadRequiredDocument } from '@src/data/api/credit-api/credit-api'
import { Constants, EApplicantType, EDocumentStatus, EFinancingProgram } from '@src/types/Constants'
import { Dispatch, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  applicantType: EApplicantType
  typeId: number
  subKey: string
  creditApplicationId: string
  financingProgramId: EFinancingProgram
  status: EDocumentStatus
  setError: Dispatch<Error>
}

const MAX_FILE_SIZE = 10485760 // 10MB

const UploadFileButton = ({
  applicantType,
  creditApplicationId,
  financingProgramId,
  typeId,
  subKey,
  status,
  setError,
}: Props) => {
  const { t } = useTranslation()

  const [uploadDocument, isPending, isError] = useUploadRequiredDocument({
    creditApplicationId,
    financingProgramId,
  })

  const onChangeHandler = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target
      if (!files) return

      const filesArray = Array.from(files)

      for (const file of filesArray) {
        if (file.size > MAX_FILE_SIZE) {
          setError(new Error(t('common.errors.fileSizeTooBig')))
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return
        }

        const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? ''
        if (!Constants.SupportedDocumentTypes.includes(fileExtension)) {
          setError(
            new Error(
              t('common.errors.unsupportedFileType', {
                supportedTypes: Constants.SupportedDocumentTypes,
              }),
            ),
          )
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return
        }

        if (!Constants.SupportedDocumentMimeTypes.includes(file.type)) {
          setError(
            new Error(
              t('common.errors.unsupportedFileType', {
                supportedTypes: Constants.SupportedDocumentTypes,
              }),
            ),
          )
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return
        }
      }

      await uploadDocument({
        financingProgramId,
        creditApplicationId,
        applicantType,
        typeId,
        subKey,
        files: filesArray,
      })
    },
    [applicantType, creditApplicationId, financingProgramId, setError, subKey, t, typeId, uploadDocument],
  )

  const getUploadButton = () => {
    if (isPending) {
      return (
        <IconButton>
          <CircularProgress />
        </IconButton>
      )
    }

    if (status !== EDocumentStatus.Approved) {
      return (
        <AsyncActionButton
          fullWidth={false}
          variant="contained"
          color="primary"
          component="label"
          isError={isError}
          sx={{ marginLeft: 0, minWidth: 'auto' }}
        >
          <input
            multiple
            type="file"
            hidden
            accept={Constants.SupportedDocumentTypes}
            onChange={onChangeHandler}
          />
          <UploadFileIcon fontSize="small" />
        </AsyncActionButton>
      )
    }

    return null
  }

  return (
    <Stack direction="row" marginTop="-0.5rem" justifyContent="center">
      {getUploadButton()}
    </Stack>
  )
}

export default UploadFileButton
