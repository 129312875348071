import { useUser } from '@src/contexts/AppContext'
import { useMemo } from 'react'
import AlertBanner from './AlertBanner'

const AlertBannerOnProduction = () => {
  const user = useUser()
  const isDev = user?.roles?.includes('Dev') ?? false
  const shouldShowProduction = useMemo<boolean>(() => {
    return isDev && import.meta.env.PROD
  }, [isDev])

  return <AlertBanner message="Production" show={shouldShowProduction} />
}

export default AlertBannerOnProduction
