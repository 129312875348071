import { Button, useTheme } from '@mui/material'

interface Props {
  id: string
  text: string
  selected: string
  onChange: (id: string) => void
}

const FeedbackOption = ({ id, text, selected, onChange }: Props) => {
  const theme = useTheme()
  return (
    <Button
      sx={{
        width: '100%',
        textAlign: 'start',
        display: 'flex',
        border: '2px solid',
        borderColor: theme.palette.divider,
        borderRadius: '2rem',
        padding: '1rem',
        textTransform: 'none',
        fontSize: '1rem',
        marginTop: '1rem',
        justifyContent: 'left',
        color: theme.palette.text.primary,
      }}
      onClick={() => onChange(id)}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          onChange(id)
        }
      }}
    >
      <div style={{ textAlign: 'right' }}>
        <input
          required
          type="radio"
          id={`feedbackType${id}`}
          name="feedbackType"
          checked={selected === id}
          style={{
            accentColor: theme.palette.secondary.main,
            width: '1.2em',
            height: '1.2em',
            verticalAlign: 'middle',
            marginRight: '10px',
          }}
          onChange={() => {}} // workaround to a warning in console
        />
        <label
          htmlFor={`feedbackType${id}`}
          style={{ verticalAlign: 'middle', cursor: 'pointer', display: 'inline-block' }}
        >
          {text}
        </label>
      </div>
    </Button>
  )
}

export default FeedbackOption
