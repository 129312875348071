import { IconButton } from '@mui/material'
import { memo } from 'react'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  onClose: () => void
}

const DialogCloseButton = ({ onClose }: Props) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: 15,
        top: 15,
        color: (theme) => theme.palette.text.secondary,
      }}
    >
      <CloseIcon />
    </IconButton>
  )
}

export default memo(DialogCloseButton)
