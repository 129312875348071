import { FormControl, FormControlProps, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from '@mui/material'
import { EnumKey } from '@src/types/common/i18n'
import { DefaultNamespace, KeyPrefix } from 'i18next'
import { ReactNode, useId, useMemo } from 'react'
import { Control, Controller, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const useTranslatedOptionEnum = <
  KPrefix extends KeyPrefix<DefaultNamespace> = KeyPrefix<DefaultNamespace>,
  Key = string | number,
>(
  prefix: KPrefix,
  enumList: EnumKey<KPrefix, Key>[],
) => {
  const { t } = useTranslation(undefined, { keyPrefix: prefix })
  return useMemo(
    () =>
      enumList.map((s) => (
        <MenuItem key={s} value={s}>
          {t(s as never) as string}
        </MenuItem>
      )),
    [enumList, t],
  )
}

export type ControlledSelectProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = {
  label: ReactNode
  control: Control<TFieldValues>
  name: TName
  options: JSX.Element[]
  emptyOptionLabel?: string
  multiple?: SelectProps['multiple']
  disabled?: boolean
} & Omit<FormControlProps, keyof ControllerRenderProps<TFieldValues, TName> | 'error'>

const ControlledSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  name,
  control,
  options: pOptions = [],
  required = false,
  emptyOptionLabel,
  multiple,
  disabled: pDisabled,
  ...props
}: ControlledSelectProps<TFieldValues, TName>) => {
  const { t } = useTranslation()
  const labelId = useId()
  const options = useMemo(
    () => [
      <MenuItem key="" value="" sx={required ? { display: 'none' } : undefined}>
        <em>{emptyOptionLabel ?? t('common.none')}</em>
      </MenuItem>,
      ...pOptions,
    ],
    [emptyOptionLabel, pOptions, required, t],
  )
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { disabled, value, onChange, ref, ...field }, fieldState: { invalid, error } }) => (
        <FormControl error={invalid} disabled={pDisabled || disabled} required={required} {...props}>
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
            inputRef={ref}
            labelId={labelId}
            label={label}
            multiple={multiple}
            value={value ?? ''}
            onChange={(e) => onChange(e.target.value !== '' ? e.target.value : null)}
            inputProps={{ formNoValidate: true }}
            {...field}
          >
            {options}
          </Select>
          {error?.message && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default ControlledSelect
