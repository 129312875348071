import { Tooltip, Typography } from '@mui/material'

interface Props {
  message: string
  color?: string
}

const DocumentNote = ({ message, color }: Props) => {
  return (
    <Tooltip title={message} placement="top">
      <Typography
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          cursor: 'pointer',
          color,
        }}
      >
        {message}
      </Typography>
    </Tooltip>
  )
}

export default DocumentNote
