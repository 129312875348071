import { InputBaseComponentProps, TextField, useTheme } from '@mui/material'
import translate from '@src/services/translate'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import React from 'react'
import { FieldError } from 'react-hook-form'
import translateErrorCode from './TranslateError'

interface Props extends InputBaseComponentProps {
  error?: FieldError
  valueList: SelectValueListItem[]
  label?: string
}

const SelectComponent = React.forwardRef<HTMLSelectElement, Props>(function SelectComponent(props, ref) {
   
  const { error, valueList, label, ...otherProps } = props as Props
  const theme = useTheme()

  return (
    <TextField
      inputRef={ref}
      label={label}
      error={error !== undefined}
      helperText={error ? translateErrorCode(error) : ''}
      fullWidth
      select
      InputLabelProps={{ shrink: true }}
      SelectProps={{
        native: true,
        inputProps: { ...otherProps },
      }}
    >
      <option aria-label="None" value="" style={{ display: 'none' }} hidden />
      {valueList.map((item) => (
        <option
          style={{
            color: item.label.toString().includes('**') ? theme.palette.text.secondary : 'none',
          }}
          key={item.value}
          value={item.value}
        >
          {translate(item.label)}
        </option>
      ))}
    </TextField>
  )
})

export default SelectComponent
