import { CardContent, CardHeader, Grid } from '@mui/material'
import { CardBorderTop } from '@src/components/common/Card'
import ControlledDatePicker from '@src/components/common/ControlledDatepicker'
import ControlledSelect, { useTranslatedOptionEnum } from '@src/components/common/ControlledSelect'
import ControlledTextField from '@src/components/common/ControlledTextfield'
import { useTermOptionList } from '@src/containers/ViewCreditApplicationPage/worksheet-hooks'
import { EFinancingProgram, FinancingProgramConfigs } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { getFirstPaymentOnMinMax, MechanicalWarrantyWorksheet } from '@src/types/WorksheetSchema'
import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  creditApplication: CreditApplication
}

const config = FinancingProgramConfigs[EFinancingProgram.MechanicalWarranty]

const MechanicalWarrantyWorksheetDetails = ({ creditApplication }: Props) => {
  const { control, setValue } = useFormContext<MechanicalWarrantyWorksheet>()
  const { t } = useTranslation()
  const paymentFrequencyOptions = useTranslatedOptionEnum('frequencyPayment', config.frequencyPayments)
  const termList = useTermOptionList(config)
  const maxActivationDate = useMemo(() => new Date(creditApplication.expiresOn), [creditApplication.expiresOn])
  const [deliveryOn, paymentFrequency] = useWatch({ control, name: ['deliveryOn', 'paymentFrequency'] })
  const [minFirstPaymentDate, maxFirstPaymentDate] = useMemo(
    () => getFirstPaymentOnMinMax(deliveryOn, paymentFrequency),
    [deliveryOn, paymentFrequency],
  )

  useEffect(() => setValue('firstPaymentOn', minFirstPaymentDate), [minFirstPaymentDate, setValue])

  return (
    <CardBorderTop>
      <CardHeader title={t('common.financing')} />
      <CardContent component={Grid} spacing={2} container>
        <Grid item xs={4}>
          <ControlledTextField
            label={t('worksheet.requestedAmount')}
            name="amountRequested"
            control={control}
            type="number"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledSelect
            label={t('worksheet.paymentFrequency')}
            control={control}
            name="paymentFrequency"
            options={paymentFrequencyOptions}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledSelect
            label={t('worksheet.loanTerm')}
            name="term"
            control={control}
            options={termList}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledDatePicker
            label={t('worksheet.activationDate')}
            name="deliveryOn"
            control={control}
            disablePast
            maxDate={maxActivationDate}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledDatePicker
            label={t('worksheet.firstPaymentOn')}
            control={control}
            name="firstPaymentOn"
            minDate={minFirstPaymentDate}
            maxDate={maxFirstPaymentDate}
            required
            fullWidth
          />
        </Grid>
      </CardContent>
    </CardBorderTop>
  )
}

export default MechanicalWarrantyWorksheetDetails
