import { EFinancingProgram } from '@src/types/Constants'
import NotFound from '@src/components/NotFound'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { UserDtoFromJwt } from '@src/types/User'
import { Merchant } from '@src/types/Merchant'
import { usePaymentPlanList } from '../ViewCreditApplicationPage/worksheet-hooks'
import PersonalLoanWorksheet from './PersonalLoanWorksheet'
import ProductsWorksheetPage from './CreateProductsWorksheetPage/ProductsWorksheet'
import MechanicalWarrantyWorksheetPage from './MechanicalWarranty/MechanicalWarrantyWorksheetPage'

interface Props {
  creditApplication: CreditApplication
  user: UserDtoFromJwt
  merchant: Merchant
  merchantsListFromParentId: Merchant[]
  listHolidays: Date[]
  allowWeekendOrHolidayActivationDate: boolean
  useIncludeInsuranceAllProvinces: boolean
}

const CreateWorksheetPage = ({
  creditApplication,
  user,
  merchant,
  merchantsListFromParentId,
  listHolidays,
  allowWeekendOrHolidayActivationDate,
  useIncludeInsuranceAllProvinces,
}: Props) => {
  const merchantPayments = usePaymentPlanList(creditApplication, merchant)

  if (creditApplication && merchant)
    switch (creditApplication.financingProgramId) {
      case EFinancingProgram.Personal:
        return (
          <PersonalLoanWorksheet
            listHolidays={listHolidays}
            merchant={merchant}
            creditApplication={creditApplication}
            user={user}
            merchantsListFromParentId={merchantsListFromParentId}
            merchantPayments={merchantPayments}
            allowWeekendOrHolidayActivationDate={allowWeekendOrHolidayActivationDate}
            useIncludeInsuranceAllProvinces={useIncludeInsuranceAllProvinces}
          />
        )

      case EFinancingProgram.Products:
        return (
          <ProductsWorksheetPage
            merchant={merchant}
            creditApplication={creditApplication}
            merchantPayments={merchantPayments}
          />
        )
      case EFinancingProgram.MechanicalWarranty:
        return <MechanicalWarrantyWorksheetPage merchant={merchant} creditApplication={creditApplication} />
      default:
        return <NotFound />
    }

  return <NotFound />
}

export default CreateWorksheetPage
