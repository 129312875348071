import { Stack, Typography } from '@mui/material'
import { FormatCurrency, formatDate } from '@src/services/Formatter'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'

interface Props {
  creditApp: CreditApplication
}
const ContractInformation = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const paymentFrequency = creditApp.worksheet?.paymentFrequency
    ? t(`frequencyPayment.${creditApp.worksheet.paymentFrequency}`)
    : null

  const paymentAmountForFrequency = creditApp.worksheet?.paymentAmountForFrequency
    ? FormatCurrency(creditApp.worksheet?.paymentAmountForFrequency)
    : null

  const loanTerm = creditApp.worksheet?.term ? `${creditApp.worksheet?.term} ${t('common.month')}` : null
  return (
    <div>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Stack direction="column">
          <Typography sx={{ fontSize: '0.8rem' }} variant="subtitle1">
            {t('worksheet.activationDate')}
          </Typography>
          <Typography sx={{ mb: 2, fontSize: '0.8rem' }}>{creditApp.worksheet?.deliveryOn}</Typography>

          <Typography sx={{ fontSize: '0.8rem' }} variant="subtitle1">
            {t('worksheet.paymentFrequency')}
          </Typography>
          <Typography sx={{ mb: 2, fontSize: '0.8rem' }}>{paymentFrequency}</Typography>

          <Typography sx={{ fontSize: '0.8rem' }} variant="subtitle1">
            {t('worksheet.firstPaymentOn')}
          </Typography>
          <Typography sx={{ mb: 2, fontSize: '0.8rem' }}>
            {creditApp.worksheet?.firstPaymentOn && formatDate(new Date(creditApp.worksheet.firstPaymentOn))}
          </Typography>
        </Stack>

        <Stack direction="column">
          <Typography sx={{ fontSize: '0.8rem' }} variant="subtitle1">
            {t('worksheet.loanTerm')}
          </Typography>
          <Typography sx={{ mb: 2, fontSize: '0.8rem' }}>{loanTerm}</Typography>

          <Typography sx={{ fontSize: '0.8rem' }} variant="subtitle1">
            {t('worksheet.paymentAmount')}
          </Typography>
          <Typography sx={{ mb: 2, fontSize: '0.8rem' }}>{paymentAmountForFrequency}</Typography>

          <Typography sx={{ fontSize: '0.8rem' }} variant="subtitle1">
            {t('worksheet.requestedAmount')}
          </Typography>
          <Typography sx={{ mb: 2, fontSize: '0.8rem' }}>
            {FormatCurrency(creditApp.worksheet?.amountRequested)}
          </Typography>
        </Stack>
      </Stack>
    </div>
  )
}

export default ContractInformation
