import { Typography } from '@mui/material'
import { FinancingProgramConfigs } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'
import AddChangeCoappButton from '../AddCoappButton'
import CancelCreditAppButton from '../CancelCreditAppButton'

interface Props {
  creditApp: CreditApplication
  canEdit: boolean
}

const CreditDeclined = ({ creditApp, canEdit }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="body1">{t('viewCreditApplication.clientDeclined')}</Typography>
      {canEdit && (
        <>
          {FinancingProgramConfigs[creditApp.financingProgramId].allowCoappAfterDecline && (
            <AddChangeCoappButton
              id={creditApp.id}
              financingProgramId={creditApp.financingProgramId}
              coapp={creditApp.coApplicant}
            />
          )}
          <CancelCreditAppButton creditApp={creditApp} />
        </>
      )}
    </>
  )
}

export default CreditDeclined
