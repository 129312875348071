import { Typography } from '@mui/material'

import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'
import CancelCreditAppButton from '../CancelCreditAppButton'

interface Props {
  creditApp: CreditApplication
}
const PrequalDocumentRequired = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="body1">{t('viewCreditApplication.requiredDocuments')}</Typography>
      <CancelCreditAppButton creditApp={creditApp} />
    </>
  )
}

export default PrequalDocumentRequired
