import {
  Download as DownloadIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import {
  Button,
  ButtonProps,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { filterProgramsWithResources } from '@src/data/resources-selectors'
import useResources from '@src/hooks/resources-hooks'
import { EFinancingProgram } from '@src/types/Constants'
import { ResourceDto } from '@src/types/ResourceDto'
import { nanoid } from 'nanoid'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

interface Props {
  supportedFinancingPrograms: EFinancingProgram[]
  language: string
}

const ResourcesMobileListBase = ({ supportedFinancingPrograms, language }: Props) => {
  const location = useLocation()
  const [open, setOpen] = useState<boolean>(Boolean(/^\/credits\/.+\/resources/.exec(location.pathname)))
  const { t } = useTranslation()
  const [resources, isFetching] = useResources(supportedFinancingPrograms, language)

  if (isFetching) {
    return <div>Loading...</div>
  }

  const programsWithResources: Record<string, ResourceDto[]> = filterProgramsWithResources(resources)

  if (programsWithResources === null || Object.keys(programsWithResources).length === 0) {
    return null
  }

  return (
    <>
      <ListItemButton onClick={() => setOpen((val) => !val)}>
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText primary={t('menus.resources')} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {Object.entries(programsWithResources).map(([programId, resourcesForProgram]) => (
            <div key={programId}>
              <Typography variant="subtitle2" sx={{ pl: 4, my: 1 }}>
                {t(`financingProgram.${programId as EFinancingProgram}`)}
              </Typography>

              <List>
                {resourcesForProgram.map((resourceDto: ResourceDto) => (
                  <ListItemButton
                    key={resourceDto.id}
                    sx={{ pl: 2 }}
                    component="a"
                    href={`/assets/resources/${resourceDto.financingProgramId}/${resourceDto.language}/${resourceDto.fileName}`}
                    download={resourceDto.fileName}
                  >
                    <ListItemIcon>
                      <DownloadIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ margin: -3 }} primary={resourceDto.fileName} />
                  </ListItemButton>
                ))}
              </List>
            </div>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export const ResourcesMobileList = memo(ResourcesMobileListBase)

export const ResourcesHeaderList = ({ supportedFinancingPrograms, language, ...props }: Props & ButtonProps) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [menuId] = useState(nanoid())
  const [btnId] = useState(nanoid())
  const [resources, isFetching] = useResources(supportedFinancingPrograms, language)

  const programsWithResources = filterProgramsWithResources(resources)
  if (programsWithResources === null || Object.keys(programsWithResources).length === 0) {
    return null
  }

  if (isFetching) {
    return (
      <Button startIcon={<DownloadIcon />} {...props}>
        {t('menus.resources')}
      </Button>
    )
  }
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id={btnId}
        aria-controls={menuId}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<DownloadIcon />}
        {...props}
      >
        {t('menus.resources')}
      </Button>
      <Menu
        id={menuId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': btnId,
        }}
      >
        {Object.entries(programsWithResources).map(([programId, resourcesForProgram]) => (
          <MenuItem key={programId}>
            <ListItemText primary={t(`financingProgram.${programId as EFinancingProgram}`)} />

            <List>
              {resourcesForProgram.map((resourceDto: ResourceDto) => (
                <ListItemButton
                  key={resourceDto.id}
                  component="a"
                  href={`/assets/resources/${resourceDto.financingProgramId}/${resourceDto.language}/${resourceDto.fileName}`}
                  download={resourceDto.fileName}
                >
                  <ListItemIcon>
                    <DownloadIcon />
                  </ListItemIcon>
                  <ListItemText primary={resourceDto.fileName} />
                </ListItemButton>
              ))}
            </List>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
export default memo(ResourcesHeaderList)
