import AsyncActionButton from '@src/components/AsyncActionButton'
import { ApplicantDto } from '@src/types/CreditApplicationSchema'

import { useContinueWithComputedIncome } from '@src/data/api/credit-api/credit-api'
import { useTranslation } from 'react-i18next'
import { EApplicantType, EFinancingProgram } from '@src/types/Constants'
import ResetBankAccountButton from './ResetBankAccountButton'
import { useCanContinueWithComputedIncome } from './incomeVerification-hooks'

interface Props {
  applicant: ApplicantDto
  hasIncomeDocumentsPendingUpload: boolean
  hasIncomeDocumentsPendingReview: boolean
  hasIncomeServiceAdjustment: boolean
  creditApplicationId: string
  financingProgramId: EFinancingProgram
  canEdit: boolean
}

const IncomeVerificationMismatch = ({
  applicant,
  hasIncomeDocumentsPendingUpload,
  hasIncomeDocumentsPendingReview,
  hasIncomeServiceAdjustment,
  creditApplicationId,
  financingProgramId,
  canEdit,
}: Props) => {
  const { t } = useTranslation()
  const [continueWithComputedIncome, isContinuingWithComputedIncome, isError, isSuccess] =
    useContinueWithComputedIncome()

  const applicantType = applicant.isPrimaryApplicant ? EApplicantType.Applicant : EApplicantType.Coapplicant

  const canContinueWithComputedIncome = useCanContinueWithComputedIncome(applicant)

  const onContinueWithComputedIncome = async () => {
    await continueWithComputedIncome({
      creditApplicationParams: { creditApplicationId, financingProgramId },
      applicantType,
    })
  }
  const incomeMismatchUnderReview =
    (hasIncomeDocumentsPendingReview || !applicant.incomeVerification?.allIncomeTasksConfirmed) &&
    !hasIncomeDocumentsPendingUpload

  return (
    <div>
      {incomeMismatchUnderReview && (
        <>
          {!hasIncomeServiceAdjustment && <p>{t('incomeVerification.incomeMismatch.noMatch')}</p>}
          <p>{t('incomeVerification.startVerification.reviewing')}</p>
          <p>{t('incomeVerification.startVerification.notified')}</p>
        </>
      )}

      {hasIncomeDocumentsPendingUpload && (
        <>
          <p>{t('incomeVerification.incomeMismatch.noMatchProvideDocs')}</p>
          {canContinueWithComputedIncome && <p>{t('incomeVerification.incomeMismatch.continueOrSubmit')}</p>}
        </>
      )}
      {!hasIncomeServiceAdjustment && canContinueWithComputedIncome && canEdit && (
        <AsyncActionButton
          variant="contained"
          color="primary"
          onClick={onContinueWithComputedIncome}
          isPending={isContinuingWithComputedIncome}
          isError={isError}
          isSuccess={isSuccess}
        >
          {t('incomeVerification.incomeMismatch.continue')}
        </AsyncActionButton>
      )}
      {!hasIncomeServiceAdjustment && canEdit && (
        <>
          <p>{t('incomeVerification.nameMismatch.reset')}</p>
          <ResetBankAccountButton
            applicantType={applicantType}
            id={creditApplicationId}
            financingProgramId={financingProgramId}
          />
        </>
      )}
    </div>
  )
}

export default IncomeVerificationMismatch
