import {
  FilterAltOutlined as FilterAltOutlinedIcon,
  FilterAltOffOutlined as FilterAltOffOutlinedIcon,
} from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { Divider, IconButton, InputAdornment, Stack, TextField, debounce } from '@mui/material'
import { ECreditApplicationStatus } from '@src/types/Constants'

interface Props {
  handleSearch: (value: string) => void
  onOpenFilterClicked: () => void
  creditApplicationStatusFilter: ECreditApplicationStatus
  hasAFilter: boolean
  handleClearFilter: () => void
}

const TableFilters = ({
  handleSearch,
  onOpenFilterClicked,
  creditApplicationStatusFilter,
  hasAFilter,
  handleClearFilter,
}: Props) => {
  const debounceOnChange = debounce(handleSearch, 1000)
  const hiddenFilters = creditApplicationStatusFilter === ECreditApplicationStatus.Draft
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <TextField
        onChange={(e) => debounceOnChange(e.target.value)}
        size="small"
        type="search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {!hiddenFilters && (
        <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
          <IconButton size="large" onClick={onOpenFilterClicked} color={!hasAFilter ? 'primary' : 'warning'}>
            <FilterAltOutlinedIcon />
          </IconButton>
          <IconButton size="large" onClick={handleClearFilter} color="primary" disabled={!hasAFilter}>
            <FilterAltOffOutlinedIcon />
          </IconButton>
        </Stack>
      )}
    </Stack>
  )
}

export default TableFilters
