import { min } from 'lodash-es'
 
import {
  AnyObject,
  bool,
  boolean,
  date,
  InferType,
  mixed,
  NumberSchema,
  object,
  ObjectSchema,
  ref,
  string,
  TestContext,
  ValidationError,
} from 'yup'
import { formatDate, convertToLocalDate, normalizeDateTime } from '@src/services/Formatter'
import { addDays, addMonths, addWeeks, isLastDayOfMonth, isWeekend } from 'date-fns'

import { t } from 'i18next'
import {
  EFinancingProgram,
  EFrequencyType,
  EPaymentPlan,
  ETermsList,
  EWorksheetStatus,
  FinancingProgramConfig,
  FinancingProgramConfigs,
  PaymentMethod,
} from './Constants'
import yupExtInt from './common/SchemaTypes'

export const buildWorksheetBaseSchema = (config: FinancingProgramConfig) =>
  object({
    amountRequested: yupExtInt.double
      .required()
      .positive()
      .min(config.minLoanAmount, () => t('common.errors.greaterOrEqual_MinimumLoanAmount'))
      .max(config.maxLoanAmount, () => t('common.errors.lowerOrEqual_MaximumLoanAmount')),

    deliveryOn: string().default(new Date().toDateString()).nonNullable().required(),

    paymentFrequency: mixed<EFrequencyType>().default(null).required(),
    paymentPlanId: mixed<EPaymentPlan>().required().default(EPaymentPlan.regularDailyInterests),

    firstPaymentOn: string().test('deliveryOn-test', function (value?: string) {
      const deliveryOn = (this.parent as { deliveryOn: string }).deliveryOn
      const twoMonthsLater = new Date(deliveryOn)
      twoMonthsLater.setMonth(twoMonthsLater.getMonth() + 2)

      return !deliveryOn || !value || (new Date(value) > new Date(deliveryOn) && new Date(value) <= twoMonthsLater)
    }),

    term: yupExtInt.integer
      .min(ETermsList[0])
      .max(ETermsList[ETermsList.length - 1])
      .nonNullable()
      .required(),

    creditApplicationId: string().default(null).nullable(),

    id: string().default(null).nullable(),

    versionTag: string().default(''),
  })

export type WorsheetDetailsDto = InferType<ReturnType<typeof buildWorksheetBaseSchema>>

export const buildEditPersonalLoanWorksheetDtoSchema = (
  hardHitReportReceivedOn: Date,
  finalDecisonMaxAmountFinanced: number,
  listHolidays: Date[],
  allowWeekendOrHolidayActivationDate: boolean,
) => {
  const defaultSchema = buildWorksheetBaseSchema(FinancingProgramConfigs[EFinancingProgram.Personal])
  return defaultSchema.shape({
    status: string().required().default(EWorksheetStatus.Draft),

    amountRequested: (defaultSchema.fields.amountRequested as NumberSchema)
      .required()
      .max(finalDecisonMaxAmountFinanced, () => t('common.errors.lowerOrEqual_finalDecisonMaxAmountFinanced'))
      .test(
        'total-merchant-payment-test',
        () => t('common.errors.notEqualMerchantPayment'),
        function (value) {
          const totalMerchantPayment = (this.parent as { merchantPayments: MerchantPayment[] }).merchantPayments.reduce(
            (acc, curr) => Number(acc) + Number(curr.amount),
            0,
          )
          return totalMerchantPayment === value
        },
      ),

    deliveryOn: string()
      .default(formatDate(new Date()).toString())
      .required()
      .test(
        'expiresOn-test',
        () => t('common.errors.deliveryOnLowerThanExpiresOn'),
        function (value) {
          const deliveryDate = new Date(value)
          const expiredDate = new Date(hardHitReportReceivedOn)
          expiredDate.setDate(expiredDate.getDate() + 90)
          return deliveryDate <= expiredDate
        },
      )
      .test(
        'deliveryOn-test',
        () => t('common.errors.deliveryOnGreaterOrEqualThanToday'),
        function (value) {
          const deliveryDate = new Date(value)
          deliveryDate.setUTCHours(0, 0, 0, 0)

          const now = new Date()
          now.setUTCHours(0, 0, 0, 0)

          return now <= deliveryDate
        },
      )
      .test(
        'deliveryOn-test',
        () => t('common.errors.deliveryOnIsHoliday'),
        function (value) {
          const deliveryDate = convertToLocalDate(value)
          const isHoliday = listHolidays?.toString().includes(formatDate(deliveryDate))
          const isNotEndOfMonthAndWeekday = isWeekend(deliveryDate) && !isLastDayOfMonth(deliveryDate)
          if (allowWeekendOrHolidayActivationDate) {
            return true
          }
          return !isHoliday && !isNotEndOfMonthAndWeekday
        },
      ),

    includeInsurance: bool().default(false),

    addFastPayments: bool().default(false),

    fundConfirmationNote: string().default(null).nullable(),

    merchantPayments: mixed<MerchantPayment[]>().default([]),
  })
}

const testInsuranceSchemaField = (textContext: TestContext<AnyObject>) => {
  const values = textContext.parent as ProductsInsurance

  const anyFieldProvided = !!values.amount || !!values.term || !!values.police

  const field = textContext.path.split('.')[1] as keyof ProductsInsurance
  const value = values[field]

  if (anyFieldProvided && !value)
    return textContext.resolve(
      textContext.createError({ path: textContext.path, message: () => t('validation:mixed.required') }),
    )

  return true
}

export const buildProductInsuranceSchema = (config: FinancingProgramConfig) =>
  object({
    provider: string().nullable().default(null),
    police: string()
      .nullable()
      .default(null)
      .test(function () {
        return testInsuranceSchemaField(this)
      }),
    term: yupExtInt.integer
      .min(config.minTerm)
      .max(config.maxTerm)
      .test(function () {
        return testInsuranceSchemaField(this)
      }),
    amount: yupExtInt.double.test(function () {
      return testInsuranceSchemaField(this)
    }),
  })

export type ProductsInsurance = InferType<ReturnType<typeof buildProductInsuranceSchema>>

export const buildMechanicalWarrantyInsuranceSchema = (config: FinancingProgramConfig) =>
  object({
    providerId: string().required().default(null),
    productId: string().required().default(null),
    policyId: string().required().default(null),
    isHighTechnology: bool().required().default(false),
    isElectric: bool().required().default(false),
    isLuxury: bool().required().default(false),
    term: yupExtInt.integer.min(config.minTerm).max(config.maxTerm).required().default(null),
    amount: yupExtInt.double.positive().required().default(null),
  })

const resolveInsuranceMaxTerm = (schema: ObjectSchema<ProductsInsurance>, vehicleFinancingTerm: number) => {
  if (vehicleFinancingTerm)
    schema.fields.term = (schema.fields.term as NumberSchema<number | null | undefined, AnyObject, null, 'd'>).max(
      vehicleFinancingTerm,
    )

  return schema
}

export const getFirstPaymentOnMinMax = (deliveryOn: string | Date, paymentFrequency: EFrequencyType): [Date, Date] => {
  const deliveryDate = normalizeDateTime(deliveryOn)
  switch (paymentFrequency) {
    case EFrequencyType.Monthly:
      return [addDays(deliveryDate, 2), addMonths(deliveryDate, 1)]
    case EFrequencyType.BiWeekly:
      return [addDays(deliveryDate, 2), addWeeks(deliveryDate, 2)]
    default:
      return [addDays(deliveryDate, 2), addDays(deliveryDate, 5)]
  }
}

const validateProductsAndMechWarrantyDeliveryOnRange = (
  val: Date,
  ctx: TestContext<AnyObject>,
): boolean | ValidationError => {
  const deliveryOn = (ctx.parent as ProductsWorksheet | MechanicalWarrantyWorksheet).deliveryOn
  const paymentFrequency = (ctx.parent as ProductsWorksheet | MechanicalWarrantyWorksheet).paymentFrequency
  if (!val || !deliveryOn || !paymentFrequency) return true

  const [minDate, maxDate] = getFirstPaymentOnMinMax(deliveryOn, paymentFrequency)
  const normalizedVal = normalizeDateTime(val)

  if (normalizedVal < minDate) {
    return ctx.createError({ message: t('validation:date.min', { min: formatDate(minDate) }) })
  }

  if (normalizedVal > maxDate) {
    return ctx.createError({ message: t('validation:date.max', { max: formatDate(maxDate) }) })
  }

  return true
}

const resolveMaxTerm = (
  schema: NumberSchema<number | undefined, AnyObject, undefined, ''>,
  values: ProductsInsurance[],
) => {
  const terms: number[] = []

  values.forEach((e) => {
    if (e) if (e.term) terms.push(e.term)
  })

  const max = min(terms)

  if (max) {
    return schema.max(max)
  }

  return schema
}

export const buildProductWorksheetSchema = () => {
  const config = FinancingProgramConfigs[EFinancingProgram.Products]
  const baseSchema = buildWorksheetBaseSchema(config)
  const baseInsuranceSchema = buildProductInsuranceSchema(config)
  return baseSchema.shape({
    deliveryOn: string()
      .isValidDate()
      .default(() => formatDate(new Date()))
      .nullable()
      .required(),
    firstPaymentOn: date()
      .required()
      .test('deliveryOn-range', (val, ctx) => validateProductsAndMechWarrantyDeliveryOnRange(val, ctx))
      .default(() => addDays(new Date(), 2)),
    term: (baseSchema.fields.term as NumberSchema)
      .required()
      .when(
        ['extendedWarranty', 'replacementOrGapInsurance', 'creditInsurance'],
        (values: ProductsInsurance[], schema) => {
          return resolveMaxTerm(schema, values)
        },
      ),
    vehicleFinancingTerm: yupExtInt.integer.nonNullable().required().min(config.minTerm).max(240),
    extendedWarranty: baseInsuranceSchema
      .when('vehicleFinancingTerm', ([vehicleFinancingTerm], schema) => {
        return resolveInsuranceMaxTerm(schema, vehicleFinancingTerm as number)
      })
      .nullable(),
    replacementOrGapInsurance: baseInsuranceSchema
      .when('vehicleFinancingTerm', ([vehicleFinancingTerm], schema) => {
        return resolveInsuranceMaxTerm(schema, vehicleFinancingTerm as number)
      })
      .nullable(),
    creditInsurance: baseInsuranceSchema
      .when('vehicleFinancingTerm', ([vehicleFinancingTerm], schema) => {
        return resolveInsuranceMaxTerm(schema, vehicleFinancingTerm as number)
      })
      .nullable(),
  })
}

export type ProductsWorksheet = InferType<ReturnType<typeof buildProductWorksheetSchema>>

export const EditPersonalLoanWorksheetDtoSchema = buildEditPersonalLoanWorksheetDtoSchema(
  new Date(Date.now() + 90 * 24 * 60 * 60 * 1000), // Adds 90 days in milliseconds to the current date
  FinancingProgramConfigs[EFinancingProgram.Personal].maxLoanAmount,
  [],
  false,
)

export type EditPersonalLoanWorksheetDto = InferType<typeof EditPersonalLoanWorksheetDtoSchema>

export type EditPersonalLoanWorksheetDtoDefaultValue = InferType<typeof EditPersonalLoanWorksheetDtoSchema>

export const merchantPaymentSchema = object({
  merchantId: string().required(),
  amount: yupExtInt.double.positive().required(),
  paymentMethod: string().default(PaymentMethod.bankTransfer).required().oneOf(Object.values(PaymentMethod)),
})

export type MerchantPayment = InferType<typeof merchantPaymentSchema>

export const computePossibleTermsDtoSchema = object({
  merchantId: string().required(),
  paymentPlanId: string().required(),
  interestRate: yupExtInt.double.positive().required(),
  hasCoapplicant: boolean().required(),
  stateIso: string().required(),
  paymentFrequency: string().oneOf(Object.values(EFrequencyType)).required(),
  deliveryOn: string().default(new Date().toDateString()).nonNullable().required('DO'),
  firstPaymentOn: date().test('deliveryOn-test', function (value?: Date) {
    const deliveryOn = (this.parent as { deliveryOn: string }).deliveryOn
    const twoMonthsLater = new Date(deliveryOn)
    twoMonthsLater.setMonth(twoMonthsLater.getMonth() + 2)

    return !deliveryOn || !value || (value > new Date(deliveryOn) && value <= twoMonthsLater)
  }),
  maxPmtAmount: yupExtInt.double.positive().required(),
  amountRequested: yupExtInt.double
    .required('AR')
    .positive()
    .min(FinancingProgramConfigs[EFinancingProgram.Personal].minLoanAmount, () =>
      t('common.errors.greaterOrEqual_MinimumLoanAmount'),
    )
    .max(FinancingProgramConfigs[EFinancingProgram.Personal].maxLoanAmount, () =>
      t('common.errors.lowerOrEqual_MaximumLoanAmount'),
    ),
  includeInsurance: boolean().required(),
})

export type ComputePossibleTermsDto = InferType<typeof computePossibleTermsDtoSchema>

export const buildMechanicalWarrantyWorksheetSchema = () => {
  const config = FinancingProgramConfigs[EFinancingProgram.MechanicalWarranty]
  const baseSchema = buildWorksheetBaseSchema(config)
  const baseInsuranceSchema = buildMechanicalWarrantyInsuranceSchema(config)
  return baseSchema.shape({
    deliveryOn: date()
      .required()
      .min(new Date())
      .default(() => new Date()),
    firstPaymentOn: date()
      .required()
      .test('deliveryOn-range', (val, ctx) => validateProductsAndMechWarrantyDeliveryOnRange(val, ctx))
      .default(() => addDays(new Date(), 2)),
    term: yupExtInt.integer.min(config.minTerm).max(ref('extendedWarranty.term')).required().default(null),
    extendedWarranty: baseInsuranceSchema.default(baseInsuranceSchema.getDefault()),
  })
}

export type MechanicalWarrantyWorksheet = InferType<ReturnType<typeof buildMechanicalWarrantyWorksheetSchema>>
