import AddIcon from '@mui/icons-material/Add'
import { Alert, AlertTitle, Box, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import { EApplicantType, EContractStatus, EFinancingProgram, FinancingProgramConfigs } from '@src/types/Constants'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCreditApplicationById } from '@src/data/api/credit-api/credit-api'
import { getCanEdit } from '@src/data/creditapp-selectors'
import NotFound from '@src/components/NotFound'
import CreditTile from './components/CreditSection/CreditTile'
import DocumentTable from './components/DocumentSection/DocumentTable'
import PrequalTile from './components/PrequalSection/PrequalTile'
import ApplicantInfoBox from './components/applicantInfoBox'
import LoanInfoBox from './components/loanInfoBox'
import { isServerAnalysingCredit, canPollCreditApp, isServerCreatingContract } from './credit-hooks'
import ContractTile from './components/ContractSection/ContractTile'
import IncomeVerificationTile from './components/IncomeVerificationSection/IncomeVerificationTile'
import FundingTile from './components/FundingSection/FundingTile'
import PageSpinner from '../../components/PageSpinner'

interface Props {
  id: string
  financingProgramId: EFinancingProgram
}
const ViewCreditApplicationPage = ({ id, financingProgramId }: Props) => {
  const { t } = useTranslation()
  const [shouldPoll, setShouldPoll] = useState(false)

  const [creditApplication, isLoadingCreditApp] = useCreditApplicationById(
    {
      creditApplicationId: id,
      financingProgramId,
    },
    shouldPoll,
  )

  const [merchant, isLoadingMerchant] = useMerchantById(creditApplication?.merchantId ?? '')

  const [error, setError] = useState<Error | null>(null)

  const canEdit = getCanEdit(creditApplication?.originSystemId, creditApplication?.editLocked)
  const navigate = useNavigate()

  useEffect(() => {
    if (creditApplication) {
      const should =
        (isServerAnalysingCredit(creditApplication) || isServerCreatingContract(creditApplication)) &&
        canPollCreditApp(creditApplication)
      setShouldPoll(should)
    }
  }, [creditApplication, isLoadingCreditApp])

  return (
    <>
      <PageSpinner isLoading={isLoadingMerchant || isLoadingCreditApp} withBackdrop />
      {error && (
        <Alert
          severity="error"
          sx={{
            width: '100%',
            marginBottom: '2rem',
          }}
        >
          <AlertTitle>{t('common.error')}</AlertTitle>
          {error.message}
        </Alert>
      )}
      {creditApplication && merchant && (
        <>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexWrap: 'wrap',
              gap: theme.spacing(2),
              mb: 2,
              justifyContent: 'space-between',
              flexDirection: 'row',
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            })}
          >
            <Grid container spacing={2} sx={{ wordWrap: 'break-word', mt: 0 }}>
              {creditApplication && merchant?.name && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={(theme) => ({
                    flex: '1 1 100%',
                    maxWidth: '100%',
                    [theme.breakpoints.up('md')]: {
                      flex: '1 1 35%',
                      maxWidth: '35%',
                    },
                  })}
                >
                  <LoanInfoBox creditApp={creditApplication} merchant={merchant} canEdit={canEdit} />
                </Grid>
              )}
              {creditApplication.applicant && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={(theme) => ({
                    flex: '1 1 100%',
                    maxWidth: '100%',
                    [theme.breakpoints.up('md')]: {
                      flex: '1 1 30%',
                      maxWidth: '30%',
                    },
                  })}
                >
                  <ApplicantInfoBox
                    financingProgramId={financingProgramId}
                    applicant={creditApplication.applicant}
                    applicantTitle={t('viewCreditApplication.applicant')}
                    applicantType={EApplicantType.Applicant}
                    creditApplication={creditApplication}
                    disabled={creditApplication?.contract?.status === EContractStatus.completed || !canEdit}
                  />
                </Grid>
              )}
              {creditApplication.coApplicant && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={(theme) => ({
                    flex: '1 1 100%',
                    maxWidth: '100%',
                    [theme.breakpoints.up('md')]: {
                      flex: '1 1 30%',
                      maxWidth: '30%',
                    },
                  })}
                >
                  <ApplicantInfoBox
                    financingProgramId={financingProgramId}
                    applicant={creditApplication?.coApplicant}
                    applicantTitle={t('viewCreditApplication.coApplicant')}
                    applicantType={EApplicantType.Coapplicant}
                    creditApplication={creditApplication}
                    disabled={creditApplication?.contract?.status === EContractStatus.completed || !canEdit}
                  />
                </Grid>
              )}
              {!creditApplication.coApplicant &&
                creditApplication?.contract?.status !== EContractStatus.completed &&
                canEdit && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={(theme) => ({
                      flex: '1 1 100%',
                      maxWidth: '100%',
                      [theme.breakpoints.up('md')]: {
                        flex: '1 1 30%',
                        maxWidth: '30%',
                      },
                    })}
                  >
                    <Paper
                      component={Grid}
                      item
                      xs
                      sx={{
                        borderRadius: 2,
                        p: 1,
                        m: 1,
                        height: '100%',
                      }}
                    >
                      <Stack direction="column" justifyContent="center" alignItems="center" height="100%" spacing={1}>
                        <IconButton
                          onClick={() => {
                            navigate(
                              `/credits/${creditApplication.financingProgramId}/${id}/edit?showTabIndex=1&addCoApp=true`,
                            )
                          }}
                        >
                          <AddIcon style={{ fontSize: '3rem' }} />
                        </IconButton>
                        {t('viewCreditApplication.addCoapp')}
                      </Stack>
                    </Paper>
                  </Grid>
                )}
            </Grid>
          </Box>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h5" component="div">
                {t('viewCreditApplication.applicationProcessTracking')}
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                useFlexGap
                sx={{
                  scrollbarWidth: 'thin',
                  overflowY: 'auto',
                  pb: 2,
                }}
              >
                {FinancingProgramConfigs[financingProgramId].displayPrequalify && (
                  <PrequalTile creditApp={creditApplication} />
                )}
                <CreditTile creditApp={creditApplication} merchant={merchant} canEdit={canEdit} />
                {FinancingProgramConfigs[financingProgramId].displayIncomeVerification && (
                  <>
                    <IncomeVerificationTile
                      financingProgramId={financingProgramId}
                      applicant={creditApplication?.applicant}
                      requiredDocuments={creditApplication?.requiredDocuments}
                      creditDecision={creditApplication?.finalCreditDecision.decision}
                      applicationStatus={creditApplication?.status}
                      creditApplicationId={creditApplication?.id}
                      merchant={merchant}
                      canEdit={canEdit}
                    />
                    {creditApplication?.coApplicant && (
                      <IncomeVerificationTile
                        financingProgramId={financingProgramId}
                        applicant={creditApplication?.coApplicant}
                        requiredDocuments={creditApplication?.requiredDocuments}
                        creditDecision={creditApplication?.finalCreditDecision.decision}
                        applicationStatus={creditApplication?.status}
                        creditApplicationId={creditApplication?.id}
                        merchant={merchant}
                        canEdit={canEdit}
                      />
                    )}
                  </>
                )}
                <ContractTile creditApp={creditApplication} isPolling={shouldPoll} canEdit={canEdit} />
                <FundingTile creditApp={creditApplication} />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <DocumentTable
              financingProgramId={financingProgramId}
              requiredDocuments={creditApplication.requiredDocuments}
              creditApplicationId={id}
              setError={setError}
              canEdit={canEdit}
            />
          </Grid>
        </>
      )}
      {!isLoadingMerchant && !isLoadingCreditApp && !creditApplication && <NotFound />}
    </>
  )
}

export default ViewCreditApplicationPage
