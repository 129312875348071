import { t } from 'i18next'

/**
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const translate = (key: any, params: Record<string, string | number> = {}): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return t(key, { replace: params }) as string
}

export default translate
