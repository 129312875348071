import { Typography } from '@mui/material'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'
import CancelCreditAppButton from '../CancelCreditAppButton'

interface Props {
  creditapp: CreditApplication
  canEdit: boolean
}
const CreditDocumentsRequired = ({ creditapp, canEdit }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="body1">{t('viewCreditApplication.requiredDocuments')}</Typography>
      {canEdit && <CancelCreditAppButton creditApp={creditapp} />}
    </>
  )
}

export default CreditDocumentsRequired
