import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import { getUser } from '@src/services/Authentification'
import { initMerchantApiClient } from '@src/data/api/api-client'
import { reportErrorToConsole } from '@src/services/error-logger'
import { UserDtoFromJwt } from '../types/User'

export interface TAppStore {
  user: UserDtoFromJwt | null
  reloadUser: () => void
}
export const AppContext = createContext<TAppStore | null>(null)

export function useAppStore() {
  const [user, setUser] = useState<UserDtoFromJwt | null>(getUser())

  const handleApiError = useCallback(
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        setUser(null)
      }
      reportErrorToConsole(error)
    },
    [setUser],
  )

  initMerchantApiClient(handleApiError)

  const reloadUser = useCallback(() => {
    setUser(getUser())
    initMerchantApiClient(handleApiError)
  }, [setUser, handleApiError])

  return useMemo(
    () => ({
      user,
      reloadUser,
    }),
    [user, reloadUser],
  )
}

export function useUser() {
  const ctx = useContext(AppContext)
  return ctx?.user
}
