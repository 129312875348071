/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack } from '@mui/material'
import ControlledTextField from '@src/components/common/ControlledTextfield'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { EServiceCategory } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  errors: FieldErrors<CreditApplication> | undefined
  loanPurposeId: EServiceCategory
  register: UseFormRegister<any>
  isGoodAndServiceLoanPurpose: boolean
  beneficiaryTypes: SelectValueListItem[]
  isVeterinaryLoanPurpose: boolean
  disableOtherName: boolean
  formControl: Control<any>
}

const FinancingAmountAndBeneficiary = ({
  errors,
  loanPurposeId,
  register,
  isGoodAndServiceLoanPurpose,
  beneficiaryTypes,
  isVeterinaryLoanPurpose,
  disableOtherName,
  formControl,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Stack spacing={2}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 1 }}>
        <InputTextField
          id="loanPurposeId"
          value={loanPurposeId ? t(`serviceCategory.${loanPurposeId}`) : ''}
          disabled
          label={t('editCreditApplication.loanPurpose')}
          {...register('loanPurposeId')}
          error={errors?.loanPurposeId}
        />
        <ControlledTextField
          id="amountRequested"
          name="requestedLoanAmount"
          control={formControl}
          InputProps={{ endAdornment: '$' }}
          label={t('editCreditApplication.amountRequested')}
          type="number"
          fullWidth
          required
        />
      </Stack>
      {!isGoodAndServiceLoanPurpose && (
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 1 }}>
          <SelectComponent
            valueList={beneficiaryTypes}
            label={t('editCreditApplication.beneficiary.beneficiary') as string}
            disabled={isVeterinaryLoanPurpose}
            {...register('beneficiaryTypeId')}
            error={errors?.beneficiaryTypeId}
          />
          {!isVeterinaryLoanPurpose && (
            <ControlledTextField
              id="otherBeneficiaryFirstName"
              name="otherBeneficiaryFirstName"
              control={formControl}
              label={t('editCreditApplication.beneficiary.otherBeneficiaryFirstName')}
              disabled={disableOtherName}
              inputProps={{ maxLength: 50 }}
            />
          )}
          <ControlledTextField
            id="otherBeneficiaryLastName"
            name="otherBeneficiaryLastName"
            control={formControl}
            label={
              !isVeterinaryLoanPurpose
                ? t('editCreditApplication.beneficiary.otherBeneficiaryLastName')
                : t('editCreditApplication.beneficiary.animalName')
            }
            required={isVeterinaryLoanPurpose}
            disabled={disableOtherName}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default FinancingAmountAndBeneficiary
