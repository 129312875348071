import { EFinancingProgram, EHomeStatus, EProvince, FinancingProgramConfigs } from '@src/types/Constants'
import { ApplicantJob, ApplicantOtherIncome, CreditApplication } from '@src/types/CreditApplicationSchema'
import { sumBy } from 'lodash-es'

export interface BusinessError {
  message: string
  params?: Record<string, string | number>
}

function isHousingExpenseValid(
  homeFeeTypeId: EHomeStatus | null | undefined,
  housingMonthly: number | null | undefined,
) {
  return (
    homeFeeTypeId === EHomeStatus.Owner ||
    (housingMonthly != null &&
      ((homeFeeTypeId === EHomeStatus.WithParents && housingMonthly >= 0) || housingMonthly > 0))
  )
}

export function validateCreditApplicationCanBeSubmittedForReview(application: CreditApplication): BusinessError[] {
  const ret: BusinessError[] = []

  const isProductAndNotQcApplicant =
    application.financingProgramId === EFinancingProgram.Products &&
    application.applicant.currentAddress.stateIso !== EProvince.quebec

  const isProductAndNotQcCoApplicant =
    application.coApplicant &&
    application.financingProgramId === EFinancingProgram.Products &&
    application.coApplicant.currentAddress.stateIso !== EProvince.quebec

  const hasCoapplicant = !!application?.coApplicant
  const applicantHousingExpensesValid =
    isHousingExpenseValid(
      application.applicant.expenses?.homeFeeTypeId,
      application.applicant.expenses?.housingMonthly,
    ) || isProductAndNotQcApplicant
  const coApplicantHousingExpensesValid =
    isHousingExpenseValid(
      application.coApplicant?.expenses?.homeFeeTypeId,
      application.coApplicant?.expenses?.housingMonthly,
    ) || isProductAndNotQcCoApplicant

  if (!isProductAndNotQcApplicant) {
    const applicantIncomes =
      Number(sumBy(application.applicant.currentJobs, (x: ApplicantJob) => x.annualSalary)) +
      Number(sumBy(application.applicant.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount))

    const coapplicantIncomes =
      Number(sumBy(application.coApplicant?.currentJobs, (x: ApplicantJob) => x.annualSalary)) +
      Number(sumBy(application.coApplicant?.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount))

    const programConfig = FinancingProgramConfigs[application.financingProgramId]

    if (
      applicantIncomes < programConfig.minIncome ||
      (hasCoapplicant && coapplicantIncomes < programConfig.minIncome)
    ) {
      ret.push({
        message: 'editCreditApplication.errors.minIncomeNotMet',
        params: { minIncome: programConfig.minIncome },
      })
    }
  }
  if (applicantHousingExpensesValid === false || (hasCoapplicant && coApplicantHousingExpensesValid === false)) {
    ret.push({ message: 'editCreditApplication.errors.expenses', params: {} })
  }
  return ret
}
