import { Grid, MenuItem } from '@mui/material'

import ControlledSelect from '@src/components/common/ControlledSelect'
import ControlledTextField from '@src/components/common/ControlledTextfield'
import InputTextFieldWithMask from '@src/components/InputMask'
import { Address } from '@src/types/AddressSchema'
import { DefaultProgramConfig, EMask, EProvince } from '@src/types/Constants'
import { useMemo } from 'react'
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldPath,
  FieldValues,
  Merge,
  UseFormRegister,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props<TFieldValues extends FieldValues> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  name: FieldPath<TFieldValues>
  isCommercial?: boolean
  formControl: Control<TFieldValues>
  includeDuration?: boolean
  supportedProvinces?: EProvince[]
  errors?: Merge<FieldError, FieldErrors<Address>> | undefined
}

const AddressComponent = <TFieldValues extends FieldValues = FieldValues>({
  register,
  name,
  errors,
  isCommercial = false,
  formControl,
  includeDuration = true,
  supportedProvinces = DefaultProgramConfig.supportedProvinces,
}: Props<TFieldValues>) => {
  const { t } = useTranslation()
  const provinceList = useMemo(
    () =>
      supportedProvinces.sort().map((stateIso) => (
        <MenuItem key={stateIso} value={stateIso}>
          {t(`province.${stateIso}`)}
        </MenuItem>
      )),
    [supportedProvinces, t],
  )

  return (
    <>
      <input type="hidden" {...register(`${name}.country`)} value="Canada" />
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item xs={12} md={3} lg={2}>
          <ControlledTextField
            name={`${name}.civicNumber` as FieldPath<TFieldValues>}
            control={formControl}
            id="civicNumver"
            label={t('editCreditApplication.address.addressNumber')}
            fullWidth
            required
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <ControlledTextField
            id="street"
            name={`${name}.street` as FieldPath<TFieldValues>}
            control={formControl}
            label={t('editCreditApplication.address.street')}
            inputProps={{ maxLength: 100 }}
            fullWidth
            required
          />
        </Grid>
        {!isCommercial && (
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <ControlledTextField
              name={`${name}.unit` as FieldPath<TFieldValues>}
              id="apartment"
              control={formControl}
              label={t('editCreditApplication.address.apartment')}
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </Grid>
        )}
        {isCommercial && (
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <ControlledTextField
              name={`${name}.suite` as FieldPath<TFieldValues>}
              control={formControl}
              id="suite"
              label={t('editCreditApplication.address.suite')}
              inputProps={{ maxLength: 10 }}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} sm={8} md={4}>
          <ControlledTextField
            name={`${name}.city` as FieldPath<TFieldValues>}
            control={formControl}
            id="city"
            label={t('editCreditApplication.address.city')}
            inputProps={{ maxLength: 50 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name={`${name}.postalCode` as FieldPath<TFieldValues>}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                id="postalCode"
                mask={EMask.postalCode}
                error={errors?.postalCode}
                label={t('editCreditApplication.address.postalCode')}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <ControlledSelect
            name={`${name}.stateIso` as FieldPath<TFieldValues>}
            id="province"
            control={formControl}
            options={provinceList}
            label={t('editCreditApplication.address.province') as string}
            fullWidth
            required
          />
        </Grid>
        {includeDuration && (
          <>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                id="addressSinceYears"
                name={`${name}.years` as FieldPath<TFieldValues>}
                control={formControl}
                type="number"
                label={t('editCreditApplication.address.since.years')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                id="addressSinceMonths"
                name={`${name}.months` as FieldPath<TFieldValues>}
                control={formControl}
                type="number"
                label={t('editCreditApplication.address.since.months')}
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default AddressComponent
