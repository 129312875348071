import { Autocomplete } from '@mui/material'
import InputTextField from '@src/components/InputTextField'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  isBanner: boolean
  merchantsList: Merchant[]
  onChange: (event: unknown, selectedMerchant: Merchant | null) => void
  errors: FieldErrors<CreditApplication> | undefined
  merchant: Merchant | null
  hasPrequalifyBeenPressed?: boolean
}

const MerchantSelector = ({ isBanner, merchantsList, onChange, errors, merchant }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      {isBanner && (
        <Autocomplete
          id="merchantList"
          options={merchantsList}
          onChange={onChange}
          fullWidth
          autoHighlight
          getOptionLabel={(option) => option?.name ?? ''}
          noOptionsText={t('common.searchYieldsNoResults')}
          value={merchant}
          renderInput={(merchantList) => (
            <InputTextField
              {...merchantList}
              id="merchant"
              label={t('common.merchant')}
              inputProps={{
                ...merchantList.inputProps,
              }}
              error={errors?.merchantId}
            />
          )}
        />
      )}
      {!isBanner && (
        <InputTextField
          id="merchant"
          value={merchant?.name ?? ''}
          disabled
          label={t('common.merchant')}
          error={errors?.merchantId}
        />
      )}
    </>
  )
}

export default MerchantSelector
