import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import yup from '@src/types/common/yup-extended'
import { setDefaultOptions } from 'date-fns'
import { enCA, frCA } from 'date-fns/locale'
import { t } from 'i18next'
import { type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  children: ReactNode
}

// le format de date CA n'est pas défini correctement dans date-fns
const dateFormats = {
  full: 'EEEE d MMMM yyyy',
  long: 'd MMMM yyyy',
  medium: 'd MMM yyyy',
  short: 'yyyy-MM-dd',
}

// Original source function. Import do not work
const buildFormatLongFn = (args: { formats: Record<string, string>; defaultWidth: string }) => {
  return (options: { width?: string } = {}) => {
    const width = options.width ? String(options.width) : args.defaultWidth
    const format = args.formats[width] || args.formats[args.defaultWidth]
    return format
  }
}

frCA.formatLong.date = buildFormatLongFn({
  formats: dateFormats,
  defaultWidth: 'full',
})

yup.setLocale({
  mixed: {
    default: () => t('validation:mixed.default'),
    required: () => t('validation:mixed.required'),
    defined: () => t('validation:mixed.defined'),
    notNull: () => t('validation:mixed.notNull'),
    oneOf: () => t('validation:mixed.oneOf'),
    notOneOf: () => t('validation:mixed.notOneOf'),
  },
  string: {
    length: ({ length }) => t('validation:string.length', { count: length }),
    email: () => t('validation:string.email'),
    min: ({ min }) => t('validation:string.min', { count: min }),
    max: ({ max }) => t('validation:string.max', { count: max }),
    matches: () => t('validation:string.matches'),
    url: () => t('validation:string.url'),
    uuid: () => t('validation:string.uuid'),
    trim: () => t('validation:string.trim'),
    lowercase: () => t('validation:string.lowercase'),
    uppercase: () => t('validation:string.uppercase'),
  },
  number: {
    min: ({ min }) => t('validation:number.min', { min }),
    max: ({ max }) => t('validation:number.max', { max }),
    lessThan: ({ less }) => t('validation:number.lessThan', { less }),
    moreThan: ({ more }) => t('validation:number.moreThan', { more }),
    positive: () => t('validation:number.positive'),
    negative: () => t('validation:number.negative'),
    integer: () => t('validation:number.integer'),
  },
  date: {
    min: ({ min }) => t('validation:date.min', { min }),
    max: ({ max }) => t('validation:date.max', { max }),
  },
  array: {
    min: ({ min }) => t('validation:array.min', { count: min }),
    max: ({ max }) => t('validation:array.max', { count: max }),
    length: ({ length }) => t('validation:array.length', { count: length }),
  },
  object: {
    noUnknown: () => t('validation:object.noUnknown'),
  },
  boolean: {
    isValue: ({ value }) => (value ? t('validation:boolean.isTrue') : t('validation:boolean.isFalse')),
  },
})

/**
 * third party lib, except MUI Core and plugins translation, locale initialisation
 * MUI components translation needs to be set in the theme
 */
const LocaleProvider = ({ children }: Props) => {
  const { i18n } = useTranslation()

  const locale = i18n.resolvedLanguage === 'fr' ? frCA : enCA
  setDefaultOptions({ locale })

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  )
}

export default LocaleProvider
