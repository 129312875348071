import { Decimal } from 'decimal.js'
import * as yup from 'yup'

const integer = yup
  .number()
  .transform((_, val) => {
    if (val === '' || val === null || val === undefined || Number.isNaN(val)) return null
    const n = Number(val)
    if (Number.isInteger(n)) return n
    return NaN
  })
  .default(null)
  .nullable()
  .typeError('common.errors.number')

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
const numberEnum = <T extends {}>(oneOf?: (string | T)[]) => {
  let ret = yup
    .mixed<T>()
    .transform((_, val) => {
      if (val === '' || val === null || val === undefined || Number.isNaN(val)) return null
      if (typeof val === 'string' && val.trim() === '') return NaN
      const n = Number(val)
      if (Number.isInteger(n)) return n
      return NaN
    })
    .nullable()

  if (oneOf) ret = ret.oneOf(oneOf as (yup.Defined<T> | null)[])
  return ret.typeError('common.errors.number')
}

const double = yup
  .number()
  .transform((_, val) => {
    if (val === '' || val === null || val === undefined || Number.isNaN(val)) {
      return null
    }
    const x = Number(String(val).replace(',', '.'))
    const n = Number(new Decimal(x).mul(100).trunc().div(100))
    if (Number.isFinite(n)) return n
    return NaN
  })
  .default(null)
  .nullable()
  .typeError('common.errors.number')

export default {
  integer,
  numberEnum,
  double,
}
