import { EProvince, EProvinceList } from './Constants'
import yupExtInt from './common/SchemaTypes'
import * as yup from './common/yup-extended'

const baseAddressCivicNumber = yup.default.string().default('')
const baseAddressStreet = yup.default.string().default('')
const baseAddressCity = yup.default.string().nullable().default('')
const baseAddressPostalCode = yup.default.string().nullable().default('').IsValidCanadianPostalCode()
const baseAddressStateIso = yup.default
  .mixed<EProvince>()
  .nullable()
  .transform((value: string | null) => {
    return value === '' ? null : value
  })
  .default(null)
  .oneOf(EProvinceList)
const baseAddressCountry = yup.default.string().nullable().default('Canada')

const fullAddressCivicNumber = baseAddressCivicNumber.required()
const fullAddressStreet = baseAddressStreet.required()
const fullAddressCity = baseAddressCity.required()
const fullAddressPostalCode = baseAddressPostalCode.required()
const fullAddressStateIso = baseAddressStateIso.required()
const fullAddressCountry = baseAddressCountry.required()

export const BaseAddressSchema = yup.default.object().shape({
  civicNumber: baseAddressCivicNumber,
  street: baseAddressStreet,
  apartment: yup.default.string().nullable(),
  suite: yup.default.string().nullable(),
  city: baseAddressCity,
  postalCode: baseAddressPostalCode,
  country: baseAddressCountry,
  stateIso: baseAddressStateIso,
  note: yup.default.string().nullable().default(''),
  years: yupExtInt.integer.min(0).notRequired().nullable().default(null),
  months: yupExtInt.integer.min(0).notRequired().nullable().default(null),
})

export type BaseAddress = yup.default.InferType<typeof BaseAddressSchema>

export const AddressSchema = BaseAddressSchema.when((v, schema) => {
  const value = v[0] as BaseAddress
  if (value?.civicNumber || value?.apartment || value?.suite || value?.street || value?.city || value?.postalCode) {
    return schema.shape({
      civicNumber: fullAddressCivicNumber,
      street: fullAddressStreet,
      city: fullAddressCity,
      postalCode: fullAddressPostalCode,
      stateIso: fullAddressStateIso,
    })
  }
  return schema
})

export type Address = yup.default.InferType<typeof AddressSchema>

export const FullAddressSchema = BaseAddressSchema.shape({
  civicNumber: fullAddressCivicNumber,
  street: fullAddressStreet,
  city: fullAddressCity,
  postalCode: fullAddressPostalCode,
  stateIso: fullAddressStateIso,
  country: fullAddressCountry,
})

export type FullAddress = yup.default.InferType<typeof FullAddressSchema>
