import AsyncActionButton from '@src/components/AsyncActionButton'
import { usePostStatus } from '@src/data/api/credit-api/credit-api'
import { EContractStatus, ECreditApplicationStatus } from '@src/types/Constants'

import { useCallback, useState } from 'react'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'
import CancelReasonsDialog from './CancelReasonsDialog'

interface Props {
  creditApp: CreditApplication
}

const CancelCreditAppButton = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const [postStatus, isPosting, isError, isSuccess] = usePostStatus()
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)

  const handleButtonClick = useCallback(async () => {
    if (creditApp.status === ECreditApplicationStatus.Active) setIsCancelDialogOpen(true)
    else
      await postStatus({
        id: creditApp.id,
        status: ECreditApplicationStatus.Active,
        financingProgramId: creditApp.financingProgramId,
      })
  }, [creditApp, postStatus])

  const handleEditClose = useCallback(() => {
    setIsCancelDialogOpen(false)
  }, [])

  const buttonText =
    creditApp.status === ECreditApplicationStatus.Cancelled ? t('viewCreditApplication.reactivate') : t('common.cancel')
  return (
    <>
      <AsyncActionButton
        fullWidth
        color="error"
        isPending={isPosting}
        isError={isError}
        isSuccess={isSuccess}
        onClick={handleButtonClick}
        disabled={creditApp?.contract?.status === EContractStatus.completed}
      >
        {buttonText}
      </AsyncActionButton>
      {isCancelDialogOpen && (
        <CancelReasonsDialog
          open={isCancelDialogOpen}
          handleClose={handleEditClose}
          creditApplicationId={creditApp.id}
          financingProgramId={creditApp.financingProgramId}
        />
      )}
    </>
  )
}

export default CancelCreditAppButton
