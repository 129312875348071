import useVersionCheck from '@src/data/api/version-check'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AlertBanner from './AlertBanner'

const AlertBannerOnDeprecatedStatus = () => {
  const { t } = useTranslation()
  const [version, isFetchingVersion] = useVersionCheck()

  const shouldShowDeprecated = useMemo<boolean>(() => {
    return import.meta.env.PROD && !isFetchingVersion && Boolean(version) && version !== import.meta.env.VITE_VERSION
  }, [isFetchingVersion, version])

  return <AlertBanner message={t('common.errors.deprecated')} show={shouldShowDeprecated} />
}

export default AlertBannerOnDeprecatedStatus
