import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, Stack, Tooltip } from '@mui/material'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { EOtherIncomeTypeList } from '@src/types/Constants'
import { ApplicantOtherIncome } from '@src/types/CreditApplicationSchema'
import { Control, FieldError, FieldErrorsImpl, Merge, UseFormRegister, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formControl: Control<any>
  errors: Merge<FieldError, FieldErrorsImpl<ApplicantOtherIncome>> | undefined
  name: string
  onDeleteClicked: () => void
  disableEdit: boolean
  toolTip: string
}

const OtherIncomeComponent = ({
  register,
  errors,
  name,
  onDeleteClicked,
  formControl,
  disableEdit,
  toolTip,
}: Props) => {
  const { t } = useTranslation()
  const typeId: number = +useWatch({
    control: formControl,
    name: `${name}.typeId`,
  })

  return (
    <Stack direction="row" spacing={1} alignItems="start">
      <Tooltip title={toolTip}>
        <SelectComponent
          valueList={EOtherIncomeTypeList.map((value) => ({ label: t(`otherIncomeTypes.${value}`), value }))}
          label={t('editCreditApplication.income.other.typeId') as string}
          {...register(`${name}.typeId`)}
          error={errors?.typeId}
          disabled={onDeleteClicked === undefined || disableEdit}
        />
      </Tooltip>
      {typeId === 6 && (
        <Tooltip title={toolTip}>
          <InputTextField
            id="otherIncomeDescription"
            error={errors?.description}
            label="Description"
            {...register(`${name}.description`)}
            disabled={onDeleteClicked === undefined || disableEdit}
            inputProps={{ maxLength: 50 }}
          />
        </Tooltip>
      )}
      <Tooltip title={toolTip}>
        <InputTextField
          id="annualAmount"
          type="number"
          error={errors?.annualAmount}
          label={t('editCreditApplication.income.other.annualAmount')}
          {...register(`${name}.annualAmount`)}
          disabled={onDeleteClicked === undefined || disableEdit}
        />
      </Tooltip>
      <Box>
        <IconButton
          id="otherIncomeDelete"
          aria-label="delete"
          color="error"
          sx={{ mt: 1 }}
          onClick={onDeleteClicked}
          disabled={disableEdit}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Stack>
  )
}

export default OtherIncomeComponent
