import { Box, Divider, Grid, Typography } from '@mui/material'
import ControlledSelect, { useTranslatedOptionEnum } from '@src/components/common/ControlledSelect'
import ControlledTextField from '@src/components/common/ControlledTextfield'
import { DatePickerInput } from '@src/components/DatePicker'

import InputTextFieldWithMask from '@src/components/InputMask'
import {
  Constants,
  EApplicantType,
  EGenderList,
  ELanguageList,
  EMaritalStatusList,
  EMask,
  ERelationToApplicantList,
} from '@src/types/Constants'
import { ApplicantDto, CreditApplication } from '@src/types/CreditApplicationSchema'
import { addYears } from 'date-fns'
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  Merge,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  register: UseFormRegister<CreditApplication | ApplicantDto>
  errors: Merge<FieldError, FieldErrors<ApplicantDto>> | undefined
  applicantType: EApplicantType
  formControl: Control<CreditApplication | ApplicantDto>
  setValue: UseFormSetValue<CreditApplication | ApplicantDto>
  getValues: UseFormGetValues<CreditApplication | ApplicantDto>
  prohibitedPhone?: string | null
  trigger: UseFormTrigger<CreditApplication | ApplicantDto>
  hasPrequalifyBeenPressed: boolean
}

const ApplicantInformationParameters = ({
  register,
  errors,
  applicantType,
  formControl,
  setValue,
  getValues,
  prohibitedPhone,
  trigger,
}: Props) => {
  const { t } = useTranslation()

  const genderList = useTranslatedOptionEnum('genderType', EGenderList)
  const maritalStatusList = useTranslatedOptionEnum('maritalStatus', EMaritalStatusList)
  const languageList = useTranslatedOptionEnum('language', ELanguageList)
  const relationToApplicantList = useTranslatedOptionEnum('relationToApplicant', ERelationToApplicantList)

  const isCoApplicant = applicantType !== EApplicantType.Applicant
  const handlePhoneOnchange = async (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target
    const phone = value.substring(1).replace(/-/g, '')
    event.target.setCustomValidity('')
    if ((!applicantType || applicantType.includes(EApplicantType.Coapplicant)) && prohibitedPhone === phone) {
      event.target.setCustomValidity(t('common.errors.coappSameMobileError'))
    } else if (applicantType.includes(EApplicantType.Applicant) && prohibitedPhone === phone) {
      event.target.setCustomValidity(t('common.errors.applicantSameMobileError'))
    }
    setValue(`${applicantType}.cellPhone`, phone, { shouldValidate: true })
    event.target.reportValidity()
    if (getValues('coApplicant')) {
      await Promise.all([trigger('applicant.cellPhone'), trigger('coApplicant.cellPhone')])
    }
  }

  return (
    <Box>
      <input type="hidden" {...register(`${applicantType}.id`)} />
      <Divider style={{ fontSize: 25 }}>
        <Typography variant="h5" gutterBottom>
          {t('editCreditApplication.information.label')}
        </Typography>
      </Divider>

      <Grid container spacing={1} rowSpacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <ControlledTextField
            id="applicantFirstName"
            name={`${applicantType}.firstName`}
            control={formControl}
            label={t('editCreditApplication.information.firstName')}
            inputProps={{ maxLength: 50 }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <ControlledTextField
            id="applicantMiddleName"
            name={`${applicantType}.middleName`}
            control={formControl}
            label={t('editCreditApplication.information.middleName')}
            inputProps={{ maxLength: 50 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <ControlledTextField
            id="applicantLastName"
            name={`${applicantType}.lastName`}
            control={formControl}
            label={t('editCreditApplication.information.lastName')}
            inputProps={{ maxLength: 50 }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <DatePickerInput
            name={`${applicantType}.birthDate`}
            control={formControl}
            label={t('editCreditApplication.information.birthDate')}
            error={errors?.birthDate}
            minDate={addYears(new Date(), -Constants.MaxYearsToDisplay)}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            name={`${applicantType}.sin`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                id="sin"
                mask={EMask.sinMask}
                error={errors?.sin}
                label={t('editCreditApplication.information.sin')}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ControlledSelect
            name={`${applicantType}.genderId`}
            control={formControl}
            options={genderList}
            label={t('editCreditApplication.information.genderId') as string}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ControlledSelect
            name={`${applicantType}.maritalStatusId`}
            control={formControl}
            options={maritalStatusList}
            label={t('editCreditApplication.information.maritalStatusId') as string}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ControlledSelect
            name={`${applicantType}.languageId`}
            control={formControl}
            options={languageList}
            label={t('editCreditApplication.information.languageId') as string}
            fullWidth
            required
          />
        </Grid>
        {isCoApplicant && (
          <Grid item xs={12} md={3}>
            <ControlledSelect
              name={`${applicantType}.relationWithApplicant`}
              control={formControl}
              options={relationToApplicantList}
              label={t('editCreditApplication.information.relationWithApplicant')}
              fullWidth
              required
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} rowSpacing={2} sx={{ pt: 2 }}>
        <Grid item xs={12} md={6}>
          <Controller
            name={`${applicantType}.cellPhone`}
            control={formControl}
            render={() => (
              <InputTextFieldWithMask
                defaultValue={getValues(`${applicantType}.cellPhone`)}
                id="cellPhone"
                mask={EMask.phoneNumberMask}
                error={errors?.cellPhone}
                label={t('editCreditApplication.information.cellPhone')}
                onBlur={handlePhoneOnchange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={`${applicantType}.homePhone`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                id="homePhone"
                inputMode="tel"
                mask={EMask.phoneNumberMask}
                error={errors?.homePhone}
                label={`${String(t('editCreditApplication.information.homePhone'))} (${String(t('common.optional'))})`}
                {...field}
                onChange={(e) => field.onChange(e.target.value.substring(1).replace(/-/g, ''))}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            id="email"
            type="email"
            name={`${applicantType}.email`}
            control={formControl}
            label={t('editCreditApplication.information.email')}
            fullWidth
            required
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
export default ApplicantInformationParameters
