import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getApiClient } from '../api-client'

const keysFactory = {
  ProvinceConfigs: () => [{ scope: 'province-configs' }] as const,
}

const GetAllProvincesConfigs = async ({
  queryKey: [_],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['ProvinceConfigs']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<ProvinceConfigs[]>('Configs/GetAllProvincesConfigs')
  return response.data
}

export function useGetProvincesConfigs(): [ProvinceConfigs[] | null, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.ProvinceConfigs(),
    queryFn: GetAllProvincesConfigs,
  })

  return [data ?? null, isFetching]
}
export interface ProvinceConfigs {
  stateIso: string
  highCostRate: number
  legalAge: number
}
