/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import { SxProps } from '@mui/material'
import { DatePicker, DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers'
import { formatDate } from '@src/services/Formatter'
import { format } from 'date-fns'
import { useCallback } from 'react'
import { Control, FieldError, useController } from 'react-hook-form'
import translateErrorCode from './TranslateError'

function makeDatePickerChangeHandler(onChange: (...event: any[]) => void, name: string, dateTimeFormat = true) {
  return (newDate: Date | null): Promise<void> => {
    const triggerEvent = {
      target: {
        name,
        value: '',
      },
    }

    if (newDate) {
      try {
        triggerEvent.target.value = format(newDate, dateTimeFormat ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')
      } catch {
        // means the date in invalid, put the string directly so that the validation might show an error
        triggerEvent.target.value = newDate.toString()
      }
    }

    onChange(triggerEvent)
    return Promise.resolve()
  }
}

interface DateTimePickerProps {
  name: string
  label: string
  control: Control<any>
  disableFuture?: boolean
  required?: boolean
  disablePast?: boolean
}

export const DateTimePicker = ({
  name,
  control,
  label,
  disableFuture = false,
  required = false,
  disablePast = false,
}: DateTimePickerProps) => {
  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
  } = useController({ name, control })
  const changeHandler = useCallback(makeDatePickerChangeHandler(onChange, name, false), [name, onChange])

  return (
    <MuiDateTimePicker
      views={['year', 'day', 'hours']}
      label={label}
      value={value}
      onChange={changeHandler}
      onAccept={onBlur}
      disableFuture={disableFuture}
      disablePast={disablePast}
      format="yyyy-MM-dd HH:mm"
      reduceAnimations
      ampm={false}
      slotProps={{
        textField: {
          ref,
          value,
          onBlur,
          error: error !== undefined,
          helperText: error ? translateErrorCode(error) : '',
          required,
          fullWidth: true,
        },
      }}
    />
  )
}

interface DatePickerProps {
  error: FieldError | undefined
  name: string
  label: string
  control: Control<any>
  disableFuture?: boolean
  shouldDisableDate?: (date: Date) => boolean
  required?: boolean
  disablePast?: boolean
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
}

export const DatePickerInput = ({
  name,
  control,
  label,
  disableFuture = false,
  error,
  disablePast = false,
  shouldDisableDate,
  minDate = new Date(0),
  maxDate,
  disabled = false,
  ...props
}: DatePickerProps) => {
  const {
    field: { onChange, onBlur, ref, value },
  } = useController({ name, control })
  const changeHandler = useCallback(makeDatePickerChangeHandler(onChange, name, false), [name, onChange])

  const date = new Date(`${formatDate(value as string)}T23:59:59.000Z`)

  return (
    <DatePicker
      label={label}
      reduceAnimations
      disableFuture={disableFuture}
      disablePast={disablePast}
      shouldDisableDate={shouldDisableDate}
      onChange={changeHandler}
      onAccept={onBlur}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      slotProps={{
        textField: {
          name,
          onBlur,
          error: Boolean(error),
          helperText: error ? translateErrorCode(error) : '',
          value: date,
          fullWidth: true,
        },
      }}
      {...props}
      value={date}
      ref={ref}
    />
  )
}

interface SimpleDatePickerProps {
  label: string
  sx?: SxProps
  name: string
  control: Control<any>
}

export const SimpleDatePicker = ({ label, sx, name, control }: SimpleDatePickerProps) => {
  const {
    field: { onChange, value },
  } = useController({ name, control })

  const changeHandler = useCallback(makeDatePickerChangeHandler(onChange, name, false), [name, onChange])

  return (
    <DatePicker
      sx={sx}
      label={label}
      value={new Date(`${value}T23:59:59.000Z`)}
      onChange={changeHandler}
      format="yyyy-MM-dd"
    />
  )
}

export { DatePickerInput as DatePicker }
export default DateTimePicker
