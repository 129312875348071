import { Container } from '@mui/material'
import { Outlet } from 'react-router-dom'
import AppHeader from './components/AppHeader'

const TemplateApp = () => {
  return (
    <>
      <AppHeader />
      <Container maxWidth="xl" sx={{ mt: 3, pb: 6, overflow: 'auto' }}>
        <Outlet />
      </Container>
    </>
  )
}

export default TemplateApp
