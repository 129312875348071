import { CircularProgress, Stack, Typography } from '@mui/material'
import { shouldDisplayInterest } from '@src/data/creditapp-selectors'
import { getFormattedPlan, getMerchantFees, getSelectedPlan, getTotalObligation } from '@src/data/worksheet-selectors'
import { FormatCurrency, formatDate } from '@src/services/Formatter'
import { ComputeFundingResultDto } from '@src/types/ComputePersonalLoanFundingResultDto'
import { EPaymentPlan } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { useTranslation } from 'react-i18next'

interface RowProbs {
  label: string
  value: string
}

const RowStack = ({ label, value }: RowProbs) => {
  return (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      <Typography>{label} :</Typography>
      <Typography>{value}</Typography>
    </Stack>
  )
}

interface Props {
  creditApplication: CreditApplication
  amountRequested: number
  hasVariableInterest: boolean
  firstPaymentOn: string
  merchant: Merchant
  paymentPlanId: EPaymentPlan
  provinceSupportsInsurance: boolean
  computedPersonalLoanFunding: ComputeFundingResultDto | null
  isComputing: boolean
}
const PersonalLoanFundingSummary = ({
  creditApplication,
  amountRequested,
  hasVariableInterest,
  firstPaymentOn,
  merchant,
  paymentPlanId,
  provinceSupportsInsurance,
  computedPersonalLoanFunding,
  isComputing,
}: Props) => {
  const { t } = useTranslation()
  const totalObligation = getTotalObligation(amountRequested, computedPersonalLoanFunding)
  const selectedPlan = getSelectedPlan(merchant, paymentPlanId)
  const mustDisplayInterests = shouldDisplayInterest(creditApplication)
  const insuranceTotal =
    (computedPersonalLoanFunding?.insuranceFee ?? 0) + (computedPersonalLoanFunding?.insuranceTax ?? 0)

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="subtitle1" fontWeight="bold">
        {t('worksheet.fundingSummary')} :
      </Typography>
      {isComputing && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {!computedPersonalLoanFunding && !isComputing && <Typography>{t('worksheet.personalFundingInvalid')}</Typography>}
      {computedPersonalLoanFunding && (
        <>
          <RowStack label={t('worksheet.requestedAmount')} value={FormatCurrency(amountRequested)} />
          {mustDisplayInterests && (
            <>
              <RowStack
                label={`${t('worksheet.interest')} (${
                  hasVariableInterest
                    ? getFormattedPlan(selectedPlan)
                    : `${creditApplication.finalCreditDecision.interestRate}%`
                })`}
                value={FormatCurrency(computedPersonalLoanFunding.totalInterestAmount)}
              />

              <RowStack
                label={`${t('worksheet.financeFee')} (${computedPersonalLoanFunding.lenderFeeRate}%)`}
                value={FormatCurrency(computedPersonalLoanFunding.lenderFee)}
              />

              {hasVariableInterest && (
                <RowStack
                  label={`${t('worksheet.merchantFee')} (${selectedPlan?.merchantFeeRate}%)`}
                  value={getMerchantFees(selectedPlan?.merchantFeeRate, amountRequested)}
                />
              )}

              {provinceSupportsInsurance && (
                <RowStack label={t('worksheet.insurance')} value={FormatCurrency(insuranceTotal)} />
              )}

              <RowStack label={t('worksheet.totalObligation')} value={FormatCurrency(totalObligation)} />

              <RowStack
                label={t('worksheet.annualPercentageRate')}
                value={`${computedPersonalLoanFunding?.effectiveRate}%`}
              />
            </>
          )}

          <Stack direction="row" justifyContent="space-between" paddingTop={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              {t('worksheet.paymentTitle')}
            </Typography>
          </Stack>

          <RowStack
            label={t('worksheet.maxPmtAmount')}
            value={FormatCurrency(creditApplication?.finalCreditDecision.maxPmtAmount)}
          />

          <RowStack label={t('worksheet.paymentFrequency')} value={t('common.monthly')} />

          <RowStack
            label={t('worksheet.monthlyPayment')}
            value={FormatCurrency(computedPersonalLoanFunding?.paymentForFrequency)}
          />

          <RowStack label={t('worksheet.firstPaymentOn')} value={formatDate(firstPaymentOn)} />

          <Stack direction="row" justifyContent="space-between" paddingTop={3}>
            <Typography fontWeight="bold">{t('worksheet.amountToBePaid')} :</Typography>
            <Typography>{FormatCurrency(amountRequested ?? 0)}</Typography>
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default PersonalLoanFundingSummary
